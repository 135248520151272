import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Popconfirm, Typography, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { connect } from "react-redux";
import Clock from "../../components/Clock";
import UserInfo from "../../helpers/user_info";
import { manageRecordRequest } from "../../redux/actions";
import { manageAuthRequest, manageAuthSuccess } from "../../redux/auth/action";

const TopBar = ({
  setCollapsed,
  collapsed,
  manageRecordRequest,
  manageAuthSuccess,
  manageAuthAsync,
}) => {
  const token = localStorage.getItem("token");
  const { Text } = Typography;
  const userData = jwtDecode(token);
  // const { manageRecordRequest, manageAuthSuccess } = props;
  const {
    token: { colorPrimary, colorWhite },
  } = theme.useToken();

  return (
    <Header
      style={{
        padding: 0,
        background: colorWhite,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0 2px 4px rgba(0,0,0,.08)",
        position: "sticky",
        width: "-webkit-fill-available",
        top: 0,
        zIndex: 999,
      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          width: 46,
          height: 46,
          backgroundColor: colorPrimary,
          color: "white",
          marginLeft: collapsed ? "-56px" : "16px",
        }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Clock />
        <Avatar
          size={36}
          icon={<UserOutlined />}
          style={{
            margin: "0 5px 5px 0",
            alignSelf: "center",
            verticalAlign: "middle",
            backgroundColor: "#87d068",
          }}
        />
        <Text
          strong
          style={{
            fontSize: "16px",
            margin: "0",
            fontWeight: "700",
            marginRight: "24px",
          }}
        >
          {userData?.sub}
        </Text>

        <Popconfirm
          styles={{ alignSelf: "center" }}
          placement="bottom"
          title={"Are you Sure ?"}
          okText="Yes"
          icon={<LogoutOutlined />}
          onConfirm={() => {
            manageRecordRequest({
              request_type: "ADD_AUDIT_DATA",
              recordId: UserInfo.getUser(),
              subjectId: `${UserInfo?.getSiteID()}-${UserInfo.getUser()}`,
              siteId: UserInfo?.getSiteID() || "",
              role: UserInfo?.getRoles() || "",
              modifiedBy: UserInfo?.getUser(),
              modifiedOn: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              auditData: [
                {
                  sectionName: "",
                  labelName: "",
                  fieldName: "",
                  oldValue: "",
                  newValue: "",
                  comment: "User Logged Out",
                },
              ],
              type: "User Authentication",
            });
            manageAuthSuccess(null, "LOGIN");
            manageAuthAsync({ request_type: "" });
            localStorage.removeItem("token");
            window.location.href = "/signin";
          }}
          cancelText="No"
        >
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            size="large"
            style={{
              marginRight: "24px",
              color: "white",
            }}
          >
            Logout
          </Button>
        </Popconfirm>
      </div>
    </Header>
  );
};

// export default TopBar;

const mapStatetoProps = ({ auth_reducer }) => {
  return { auth_reducer };
};
const mapDispatchtoProps = {
  manageRecordRequest: manageRecordRequest,
  manageAuthSuccess: manageAuthSuccess,
  manageAuthAsync: manageAuthRequest,
};
export default connect(mapStatetoProps, mapDispatchtoProps)(TopBar);
