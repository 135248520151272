module.exports = {
  token: {
    colorPrimary: "#0073aa",
    colorSecondary: "rgb(0 115 170 / 50%)",
    colorTeritiary: "#757575",
    colorTextSecondary: "#0ab1db",
    colorInfo: "#0073aa",
    colorSuccess: "#0073aa",
    // colorBgContainer: "#f3f3f3",
    colorWhite: "#fff",
    colorBlack: "#000",
    fontFamily: "Mada,sans-serif",
  },
  components: {
    // Table: {
    //   headerBg: "#0073aa",
    //   headerColor: "#fff",
    //   headerSortHoverBg: "#0073aa",
    //   headerSortActiveBg: "#0ab1db",
    // },

    Menu: {
      itemBg: "#fff",
      itemActiveBg: "#0073aa",
      itemHoverBg: "#0073aa",
      itemHoverColor: "#fff",
      colorBgTextActive: "#000",
      colorLinkActive: "#0073aa",
      colorBgTextHover: "#fff",
      borderRadius: 0,
      borderRadiusOuter: 0,
      itemBorderRadius: 0,
      itemSelectedBg: "#0073aa",
      itemSelectedColor: "#fff",
      colorText: "#000",
      colorTextLabel: "#000",
    },
    Collapse: {
      headerBg: "#e9fbfb",
    },

    Card: {
      colorBgContainer: "#fff",
      padding: "10px",
    },
  },
};
