import Swal from "sweetalert2";
import theme from "../helpers/theme";
import "./swalStyles.css";
export const SwalWarning = (text) => {
  Swal.fire({
    icon: "Warning",
    title: "Oops...",
    text: text,
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};

export const SwalSuccess = (text) => {
  Swal.fire({
    // icon: <CheckCircleOutlined />,
    icon: "success",
    title: "Success",
    text: text,
    confirmButtonColor: theme.token.colorPrimary,
    customClass: "swal-font",
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};

export const SwalError = (text) => {
  Swal.fire({
    icon: "error",
    title: "Error",
    text: text,
    confirmButtonColor: theme.token.colorPrimary,
    customClass: "swal-font",
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};
