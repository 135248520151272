import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/scss/style.scss";
import NextApp from "./NextApp";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
axios.defaults.baseURL = window["apiBaseUrl"];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<NextApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
