import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { Footer } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SwalError, SwalSuccess } from "../../components/Swal";
import UserInfo from "../../helpers/user_info";
import login_bg from "../../logo.png";
import { MANAGE_AUTH_REQUEST, manageRecordRequest } from "../../redux/actions";
import { manageAuthRequest } from "../../redux/auth/action";
import { footerText } from "../../util/config";
const SignIn = (props) => {
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    login_info,
    auth_loading,
    auth_error,
    auth_success,
    auth_action,
    auth_request_type,
  } = props.auth_reducer;
  const { manageAuthAsync, manageRecordRequest } = props;

  function logIn(data) {
    manageAuthAsync({ request_type: "LOGIN", payload: { ...data } });
  }

  useEffect(() => {
    if (auth_action === MANAGE_AUTH_REQUEST) {
      if (auth_request_type === "LOGIN") {
        if (auth_success) {
          localStorage.setItem("token", login_info?.accessToken);

          manageRecordRequest({
            request_type: "ADD_AUDIT_DATA",
            recordId: UserInfo.getUser(),
            subjectId: `${UserInfo?.getSiteID()}-${UserInfo.getUser()}`,
            siteId: UserInfo?.getSiteID() || "",
            role: UserInfo?.getRoles() || "",
            modifiedBy: UserInfo.getUser(),
            modifiedOn: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            auditData: [
              {
                sectionName: "",
                labelName: "",
                fieldName: "",
                oldValue: "",
                newValue: "",
                comment: "User Logged In",
              },
            ],
            type: "User Authentication",
          });
          // message.success("Logged In Successfully");
          SwalSuccess("Logged In Successfully");
          // window.location.href = "/";
          history.push("/");
        } else if (auth_error) {
          // message.error(
          //   auth_error?.error === "Bad credentials"
          //     ? "Invalid Credentials"
          //     : auth_error?.error
          // );
          SwalError(
            auth_error?.error === "Bad credentials"
              ? "Invalid Credentials"
              : auth_error?.error
          );
        }
      }
    }
  }, [
    manageRecordRequest,
    auth_loading,
    auth_action,
    auth_error,
    auth_request_type,
    auth_success,
    login_info,
  ]);

  return (
    <div style={{ height: "100vh" }}>
      <Row align={"center"}>
        {!navigator.onLine && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "red",
            }}
          >
            <span style={{ color: "white" }}>
              Please Check Internet Connection
            </span>
          </div>
        )}
        <Col
          xxl={6}
          xl={8}
          lg={8}
          md={10}
          sm={12}
          xs={16}
          style={{ height: "90vh", display: "flex", alignItems: "center" }}
        >
          <Card
            style={{
              boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.05)",
              padding: "32px",
            }}
            cover={
              <img
                alt="example"
                src={login_bg}
                style={{ margin: "32px 0 50px 0" }}
              />
            }
          >
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={logIn}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      className="site-form-item-icon"
                      style={{ paddingRight: "5px" }}
                    />
                  }
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined
                      className="site-form-item-icon"
                      style={{ paddingRight: "5px" }}
                    />
                  }
                  type="password"
                  placeholder="Password"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Row align="center">
                <Col>
                  <Form.Item style={{ alignItems: "center" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Footer
        style={{
          textAlign: "center",
          position: "fixed",
          bottom: "0",
          width: "100vw",
          padding: "10px 0",
          fontWeight: "500",
          fontSize: "14px",
          fontFamily: "Mada,sans-serif",
          boxShadow: "0 0 5px 5px rgba(0,0,0,0.025)",
          backgroundColor: "white",
        }}
      >
        {footerText}
      </Footer>
    </div>
  );
};
const mapStatetoProps = ({ auth_reducer }) => {
  return { auth_reducer };
};
const mapDispatchtoProps = {
  manageAuthAsync: manageAuthRequest,
  manageRecordRequest: manageRecordRequest,
};
export default connect(mapStatetoProps, mapDispatchtoProps)(SignIn);
