import { Tag } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

function Clock() {
  const [time, setTime] = useState(new Date());
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Tag
      style={{
        marginRight: "20px",
        fontWeight: "600",
        fontSize: "15px",
      }}
    >{`${dayjs(time).format("DD-MM-YYYY, HH:mm:ss")} (${timeZone})`}</Tag>
  );
}

export default Clock;
