export const INIT_URL = "INIT_URL";

export const MANAGE_AUTH_REQUEST = "MANAGE_AUTH_REQUEST";
export const MANAGE_AUTH_SUCCESS = "MANAGE_AUTH_SUCCESS";
export const MANAGE_AUTH_ERROR = "MANAGE_AUTH_ERROR";

export const MANAGE_USER_REQUEST = "MANAGE_USER_REQUEST";
export const MANAGE_USER_SUCCESS = "MANAGE_USER_SUCCESS";
export const MANAGE_USER_ERROR = "MANAGE_USER_ERROR";

export const MANAGE_ADMIN_REQUEST = "MANAGE_ADMIN_REQUEST";
export const MANAGE_ADMIN_SUCCESS = "MANAGE_ADMIN_SUCCESS";
export const MANAGE_ADMIN_ERROR = "MANAGE_ADMIN_ERROR";

export const MANAGE_RECORD_REQUEST = "MANAGE_RECORD_REQUEST";
export const MANAGE_RECORD_SUCCESS = "MANAGE_RECORD_SUCCESS";
export const MANAGE_RECORD_ERROR = "MANAGE_RECORD_ERROR";

export * from "./admin/action";
export * from "./record/action";
