import { Layout, theme } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { footerText } from "../../util/config";
import Sidebar from "./SideBar";
import Topbar from "./TopBar";
const LayoutContainer = ({ children }) => {
  const { Footer, Content } = Layout;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div>
      <Layout>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout>
          <Topbar setCollapsed={setCollapsed} collapsed={collapsed} />
          <Content
            style={{
              // margin: "64px 16px",
              marginBottom: "32px",
              padding: 24,
              minHeight: "100vh",
              background: "#f5f5f5",
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
          {/* <Footer
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {footerText}
          </Footer> */}
          <Footer
            style={{
              textAlign: "center",
              bottom: "0",
              padding: "7px 0",
              fontWeight: "500",
              fontSize: "14px",
              fontFamily: "Mada,sans-serif",
            }}
          >
            {footerText}
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutContainer;

const LayourWrapper = styled.div`
  .wrapper {
    flex-direction: row;
    height: auto;
    min-height: calc(100vh - 70px);
  }

  .main {
    width: calc(100% - 100px);
    padding: 22px 22px;
    margin-left: 100px;
  }
`;
